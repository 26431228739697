import styled from 'styled-components';

const ContactStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 15px 60px 15px;

  p {
    color: var(--main);
    width: 100%;
    text-align: center;
    padding: 1rem 0;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    text-align: left;

    @media (min-width: 440px) {
      width: 80%;
    }

    @media (min-width: 920px) {
      width: 70%;
      padding: 5rem 6rem;
    }

    fieldset,
    textarea {
      border: none;
      width: 100%;
      padding: 0;

      & button {
        width: 100%;
      }
    }

    label,
    input {
      width: 100%;
    }

    input,
    textarea {
      box-sizing: border-box;
      margin: 10px 0;
      border: none;
      background: var(--bg-main);
      padding: 10px 20px;
      outline: var(--accent);
      margin-bottom: 20px;
      border: 2px solid var(--grey);

      &:focus {
        border: 2px solid var(--accent);
      }
    }

    textarea {
      height: auto;
      min-height: 15rem;
      resize: none;
    }

    .siroop {
      display: none;
    }

    .submit-button {
      width: 20%;
    }
  }
`;

export default ContactStyles;
