import React from "react";
import ContactPageStyles from "../styles/ContactPageStyles";
import SEO from "../components/SEO";
import Form from "../components/Form";

const description = "Voor alle elektriciteitswerken of elektriciteit aansluitingen in Itegem en Heist op den Berg voorzien wij U graag van een offerte. Neem met ons contact op voor Uw offerte";

const ContactPage = ({ location }) => {
  return (
    <ContactPageStyles>
      <SEO title='Contact | Stetrics algemene elektriciteitswerken' location={location} description={description} />
      <h1>Contact</h1>
      <p>
        Wenst U een vrijblijvende prijsofferte of liever een afspraak ter plaatse?
      </p>
      <p>
        Neem dan zeker contact op. Wij helpen U graag verder!
      </p>
      <Form />
    </ContactPageStyles>
  );
};

export default ContactPage;
